
import OfferCard from '@/components/offer_card'
import SupplierSelect from '@/components/supplier_select'
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    OfferCard,
    SupplierSelect,
    PageNavbar,
    LoadingSpinner
  }

  mounted: ->
    @querystring = @$router.currentRoute.query.search || ''
    @on_search() if @querystring.length > 0
    @$refs['search-input'].focus()

  data: -> {
    error: false
    loading: false
    next_page: 1
    results: []
    querystring: ''
    queried: ''
  }

  methods: {
    on_search: (event) ->
      @error = false
      @queried = @querystring
      @next_page = 1
      @$router.push({ query: { search: @querystring } }).catch (error) ->
        return if String(error).includes('Avoided redundant navigation to current location')
        console.error 'search push catch:', error
      @loading = true
      @$store.dispatch('analytics/search', @querystring)
      @$store.dispatch('offers/search', { q: @querystring, page: 1 })
        .then (response) =>
          @results = response.offers
          @next_page = response.pagination.next_page
        .catch (error) =>
          @error = true
          console.error 'search catch:', error
        .finally => @loading = false

    on_scroll: (e) ->
      return unless e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight
      return if @loading
      return if @next_page == null

      @loading = true
      @$store.dispatch('analytics/search', @querystring)
      @$store.dispatch('offers/search', { q: @querystring, page: @next_page })
        .then (response) =>
          @results = @results.concat(response.offers)
          @next_page = response.pagination.next_page
        .catch (error) =>
          @error = true
          console.error 'search catch:', error
        .finally => @loading = false


  }

}
