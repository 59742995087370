

export default {
  name: 'PageNavbar',
  props: {
    target: String,
    text: String
  },
  computed: {
    can_go_back: ->
      @$router.currentRoute.path != '/'
    ,
    supplier: ->
      @$store.state.catalog.supplier
  },
  methods: {
    go_back: ->
      if @target then @$router.push(@target) else @$router.back()
  }
}
