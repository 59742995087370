
import LoadingSpinner from '@/components/loading_spinner'
import { number_to_currency } from '@/lib/helpers'

export default {
  components: { LoadingSpinner }

  props: {
    offer: { type: Object }
  }

  computed: {
    is_stokvel_offer: ->
      @$store.state.application.features.stokvel && @offer.stokvel

    formatted_price: ->
      currency = @$store.getters['application/currency']
      unit = if @offer.per_kg then "/ #{@offer.per_kg_symbol}" else ''
      "#{currency} #{@offer.price}#{unit}"

    cart_qty: ->
      @$store.getters['cart/offer_qty'](@offer.id)

    formatted_cart_qty: ->
      @$store.getters['cart/formatted_offer_qty'](@offer.id)

    cart_value: ->
      @$store.getters['cart/offer_total_in_cents'](@offer.id)
  }

  methods: {
    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    show_add_to_cart: ->
      @$store.commit('offers/adding_to_cart', @offer)
  }
}
